/*
 * Copyright (C) 2023 SailPoint Technologies, Inc.  All rights reserved.
 */
import { AppShellService } from '../app-shell.service';
import { Histogram } from './metrics.model';
import { MetricService } from './metrics.service';
import RumApp, { HistogramProperties } from './mfe-app-monitor';

const BUCKETS = [0, 2, 4, 8, 10, 15, 30, 60, 90];

interface InitArgs {
	apiUrl: string;
	startTime: number;
	debug?: boolean;
	appShellService: AppShellService;
}

export class LoadTimeMetricsService {
	private pageLoadTimeMetric: Histogram;
	private appLoadTimeMetric: Histogram;

	private metricService: MetricService;
	private startTime: number;
	private monitoredApps: Record<string, RumApp>;
	private appLoadStartTime: number;
	private appLoadEndTime: number;
	private appShellMetricName = 'appshell';

	/**
	 *
	 * @param nameContext - The identifier of the microfront End from which we are going to store metrics
	 * @param startTime - The initial loading time to capture the metrics
	 * @param appShellService - The instance for app shell service
	 */
	constructor({ apiUrl, startTime, appShellService }: InitArgs) {
		this.metricService = new MetricService(apiUrl, appShellService);
		this.startTime = startTime;
		this.monitoredApps = {};

		this.pageLoadTimeMetric = this.buildHistogram({
			metricName: `ui_common_page_load_seconds`,
			help: 'The time it takes from navigation start to  the end of the routing event.'
		});

		this.appLoadTimeMetric = this.buildHistogram({
			metricName: `ui_common_app_load_seconds`,
			help: 'The time it takes from the moment the script starts executing to the end of the routing event.'
		});
	}

	// remove all MFE apps so we send the objects to garbage collection
	// do it before any other call on the before-app-change event
	public clearApps() {
		this.monitoredApps = {};
	}

	/*
	 * call this method when the MFE router starts executing for the first time.
	 * It helps differentiate this timestamp from the timestamp generated when the js script first loads
	 * @param (number) timestamp The timestamp in milliseconds (using performance.now)
	 */
	public trackApplicationStart(timestamp: number) {
		this.appLoadStartTime = timestamp;
	}

	/*
	 * call this method when the MFE router has finished mounting for the first time.
	 * it will observe the metrics page load time and the app load (mfe router) time
	 * @param (number) timestamp The timestamp in milliseconds (using performance.now)
	 */
	public trackApplicationEnd(timestamp: number) {
		this.appLoadEndTime = timestamp;

		this.pageLoadTimeMetric.observe(this.appLoadEndTime / 1000, { app: `MFE_${this.appShellMetricName}` });
		this.appLoadTimeMetric.observe((this.appLoadEndTime - this.appLoadStartTime) / 1000, {
			app: `MFE_${this.appShellMetricName}`
		});
	}

	// keeps track of every single MFE app that is mounted
	public monitorApp(appName: string) {
		if (!this.monitoredApps[appName]) {
			this.monitoredApps[appName] = new RumApp(appName, this.metricService);
		}
	}

	// call this to start tracking when the router mounts/unmounts
	public observeAppChangeStart(appName: string) {
		if (this.monitoredApps[appName]) {
			const app = this.monitoredApps[appName];
			app.beforeAppChange();
		}
	}

	// call this to complete tracking when the router mounts/unmounts
	public routingComplete(appName: string, timestamp: number) {
		if (this.monitoredApps[appName]) {
			const app = this.monitoredApps[appName];
			app.afterRouting({ timestamp });
		}
	}

	private buildHistogram({ metricName, help, buckets = BUCKETS }: HistogramProperties) {
		return this.metricService.createHistogram({
			buckets,
			help,
			name: metricName
		});
	}
}
