/*
 * Copyright (C) 2022 SailPoint Technologies, Inc. All rights reserved.
 */
import { URLsSets } from '../../src/common/model/url.model';
import { AppShellLayerManagerProvider } from './app-shell-layer-manager.model';
import { LayerManagerService } from './app-shell-layer-manager.service';
import { AppShellNavigationService } from './app-shell-navigation.service';
import { AppShellStateService } from './app-shell-state.service';
import { AppShellUrlsProvider } from './app-shell-urls.model';
import { AppShellUrlsService } from './app-shell-urls.service';
import {
	AppShellNavigationProvider,
	AppShellServiceMethods,
	LaunchDarklyContextData,
	MfeAuthContext,
	MfeAuthCredentials,
	MfeContextData,
	MfeLoginContext,
	MfeRequestContext,
	MfeTenantContext,
	MfeUserContext
} from './app-shell.model';
import { AuthCredentialsProvider } from './auth-credential.model';
import { freezeCopy } from './deep-freeze';
import { FeatureFlagService } from './feature-flag.service';
import { MfeInfo } from './mfe-info.model';
import { TLSData } from './tls.model';

interface IAppShellServiceArgs {
	data: MfeContextData;
	authCredentialsService: AuthCredentialsProvider;
	ldContext: LaunchDarklyContextData;
	tlsData: TLSData;
	urlData: URLsSets;
}
/**
 * The AppShellService is responsible for storing data that should
 * be persisted across MFEs. This service is created when the AppShell
 * starts up, and then the instance is passed down to MFEs via singleSpaProps in the
 * `appShellService` property.
 */
export class AppShellService implements AppShellServiceMethods {
	private appShellNavigationService: AppShellNavigationService;
	private appShellStateService: AppShellStateService;
	private appShellUrlsService: AppShellUrlsService;
	private authCredentialsService: AuthCredentialsProvider;
	private featureFlagService: FeatureFlagService;
	private readonly data: Readonly<MfeContextData>;
	private readonly tlsData: Readonly<TLSData>;

	constructor({ data, authCredentialsService, ldContext, tlsData, urlData }: IAppShellServiceArgs) {
		this.data = freezeCopy(data);
		this.tlsData = tlsData;
		this.appShellStateService = new AppShellStateService();
		this.authCredentialsService = authCredentialsService;

		this.featureFlagService = new FeatureFlagService(data.tenantContext, ldContext, data.userContext);
		this.appShellNavigationService = new AppShellNavigationService(data.requestContext);

		this.appShellUrlsService = new AppShellUrlsService({
			data: urlData
		});
	}

	/**
	 * Extend the API of the AppshellService through a proxy.
	 * We extend the get attributes by definig a handler of the get method
	 * Internally, it creates a LayerManager which requires specific MFE information.
	 * @param {Object} mfeInfo  - Info that will help in identifying a specific MFE
	 * @param {string} mfeInfo.name - The unique name of the MFE proxied within this appshell
	 */
	public getProxiedAppshell(mfeInfo): AppShellService {
		const proxyHandler = this.getProxyHandler(mfeInfo);
		return new Proxy(this, proxyHandler);
	}

	/*
	 * Service providing info about SailPoint's products urls (routing)
	 */
	async getAppShellUrlsProvider(): Promise<AppShellUrlsProvider> {
		return this.appShellUrlsService;
	}

	async getMfeContextV1(): Promise<Readonly<MfeContextData>> {
		return freezeCopy(this.data);
	}

	/**
	 * Get the user context data object for supply to MFEs.
	 * @returns The user context data object
	 */
	async getUserContextV1(): Promise<Readonly<MfeUserContext>> {
		return freezeCopy(this.data.userContext);
	}

	/**
	 * Get the tenant context data object for supply to MFEs.
	 * @returns The tenant context data object
	 */
	async getTenantContextV1(): Promise<Readonly<MfeTenantContext>> {
		return freezeCopy(this.data.tenantContext);
	}

	/**
	 * Get the authentication context data object for supply to MFEs.
	 * @returns The authentication context data object
	 */
	async getAuthContextV1(): Promise<Readonly<MfeAuthContext>> {
		return freezeCopy(this.data.authContext);
	}

	/**
	 * Get the request context data object for supply to MFEs.
	 * @returns The request context data object
	 */
	async getRequestContextV1(): Promise<Readonly<MfeRequestContext>> {
		return freezeCopy(this.data.requestContext);
	}

	/**
	 * Get the valid access token for supply to MFEs.
	 * MFE's should not cache this token. Instead they should request a new access token each time it is needed.
	 * If an MFE caches the access token then it runs into the possibility of the access token expiring.
	 * @returns AuthCredentials - authorization credentials data
	 */
	async getAuthCredentialsV1(): Promise<Readonly<MfeAuthCredentials>> {
		return this.authCredentialsService?.getMfeCredentialsV1();
	}

	/**
	 * Get the EventBus service
	 */
	async getAppShellStateProvider(): Promise<AppShellStateService> {
		// we don't have yet to wait for any attributes to load
		return this.appShellStateService;
	}

	/**
	 * Get the navigation service
	 * @returns AppShellNavigationService
	 */
	async getAppShellNavigationProvider(): Promise<AppShellNavigationProvider> {
		return this.appShellNavigationService;
	}

	/**
	 * Get the feature flag service for supply to MFEs
	 * @returns FeatureFlagService - the feature flag service
	 */
	async getFeatureFlagProvider(): Promise<FeatureFlagService> {
		return this.featureFlagService.ldClient.waitUntilReady().then(() => this.featureFlagService);
	}

	/**
	 * Get the login context data object only if we're at the login page
	 * @returns Login context data
	 */
	async getLoginContextV1(): Promise<Readonly<MfeLoginContext>> {
		return freezeCopy(this.data.loginContext);
	}

	/**
	 * Get the TLS service for supply to MFEs
	 * @returns TLS data
	 */
	async getTLSData(): Promise<TLSData> {
		return freezeCopy(this.tlsData);
	}

	/**
	 * @returns {Promise<undefined>} - Non proxied
	 * @returns {Promise<LayerManager>} layerManager - Proxy implemented in the static method <createProxyHandler>. A service exposing the way to send messages between layers.
	 */
	async getAppShellLayerManagerProvider(): Promise<AppShellLayerManagerProvider | undefined> {
		return undefined;
	}

	/**
	 * @returns {Promise<undefined>} - Non proxied
	 * @returns {Promise<string>} mfeName - Proxy implemented in the static method <createProxyHandler>. The name of the proxied MFE.
	 */
	async getMfeNameV1(): Promise<string | undefined> {
		return undefined;
	}

	private getProxyHandler(mfeInfo: MfeInfo) {
		const layerManager = new LayerManagerService(mfeInfo);
		return {
			/**
			 * The actual proxy handler og the object's <get> methods
			 * @param target The original object. In this case, the appShellService
			 * @param property The name of the called method inthe object
			 * @param receiver The proxy object, which in this case is  <this>.
			 * @returns
			 */
			get: (target, property, receiver) => {
				if (property === 'getMfeNameV1') {
					return async () => mfeInfo.name;
				} else if (property === 'getAppShellLayerManagerProvider') {
					return async () => layerManager;
				}
				return Reflect.get(target, property, receiver);
			}
		};
	}
}
